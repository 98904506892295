import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import { Helmet } from 'react-helmet'
import ArticlePreview from '../components/article-preview'
import Layout from '../components/layout';
import styled from 'styled-components';
import { ArticleList, Heading, Wrapper } from '../components/misc';

const BlogIndex: React.FunctionComponent<{ data: any }> = (props) => {
  const posts: any[] = get(props, 'data.allContentfulBlogPost.edges')
  const today = new Date()

  return (
    <Layout>
      <div>
        <Helmet title="Blog" />
        <Hero>
          Blog
        </Hero>
        <Wrapper>
          <h2>Recent articles</h2>
          <ArticleList>
            {posts.filter(x => process.env.GATSBY_ENV !== "production" || new Date(x.node.publishDateRaw) < today).map(({ node }) => {
              return (
                <li key={node.slug}>
                  <ArticlePreview article={node} />
                </li>
              )
            })}
          </ArticleList>
        </Wrapper>
      </div>
    </Layout>
  )
}

export default React.memo(BlogIndex)

export const pageQuery = graphql`
  query BlogIndexQuery {
    allContentfulBlogPost(sort: { fields: [publishDate], order: DESC }) {
      edges {
        node {
          title
          slug
          publishDate(formatString: "MMMM Do, YYYY")
          publishDateRaw: publishDate
          tags
          heroImage {
            sizes(maxWidth: 350, maxHeight: 196, resizingBehavior: SCALE) {
              ...GatsbyContentfulSizes_withWebp
            }
          }
          body {
            childMarkdownRemark {
              fields {
                readingTime {
                  text
                }
              }
            }
          }
          description {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  }
`


const Hero = styled.div`
display: flex;
justify-content: center;
align-items: center;
height: 61.8vh;
max-height: 400px;
background: #e1e1e1;
font-size: 2em;
overflow: hidden;
`
